import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthWrapper from "../Authentication/AuthWrapper";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
 
const DemoRequestBusiness = () => {
  const { pathname } = useLocation();
   const { loading, userCreated, isAuthenticated } = useSelector(
    (store) => store.auth
  );
  

  return (
    <AuthWrapper>
      <div className="form-container d-flex justify-content-center ">
      
        {!userCreated && !pathname.includes("activate") && (
          <div className="auth-form ml-md-4">
            <h4 className="text-center mt-4 black">
            Request for a Business Account for your Organization
            </h4>
            <form>
              <CustomInput
                type="text"
                label="Name"
                id="username"
                
              />
              <CustomInput
                type="text"
                label="Email"
                id="email"
                
              />
             
              <p className="pt-1 pb-1 mb-0 small-text text-center">
                By requesting, I agree to the{" "}
                <a
                  href="https://workbrook.com/apps-term-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                of this application
              </p>  

              <CustomButton
                type="submit"
                backgroundColor="black"
                color="#fff"
                text="Submit Request"
                 additionalClass="my-5"
             
                
              />
            </form>
           
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default DemoRequestBusiness;
