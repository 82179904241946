import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";

import ReviewPeformance from "./ReviewPeformance";
import SupervisorApproval from "./SupervisorApproval";
import SupervisorReview from "./SupervisorReview";
import RewardPerformance from "./RewardPerformance";
import SupervisorReward from "./SupervisorReward";
import AdminReview from "./AdminReview";
import AdminApproval from "./AdminApproval";



import "./index.scss";
import { getReferralJobs } from "../../../redux/actions/jobActions";

const subMenuOptionsByTab = {
  Supervisor: [
     ["Review Performance", "/performance/supervisorreview"],
    ["Approve Performance", "/performance/supervisorapproval"],
  
    ["Reward Performance", "/performance/supervisorreward"],
  ],
  HR: [
    ["Confirm Performance", "/performance/adminapproval"],
    ["Admin Review", "/performance/adminreview"],
    ["Reward Performance", "/performance/rewardperformance"],
  ],
};

const Recognition = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Supervisor"); 
  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    if (user_type === "1") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dispatch]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const displaySection = () => {
    if (selectedTab === "Supervisor" && pathname.includes("supervisorreview")) {
      return <SupervisorReview />;
      

      // } else if (selectedTab === "Supervisor") {
      //   return (
      //     <>
      //       <ReviewPeformance />
      //       <SupervisorApproval />
      //       <RewardPerformance />
      //     </>
      //   );
      // } else if (selectedTab === "HR") {
      //   return <AdminApproval />;

      // Add HR-related components here if needed
      // return <HrComponent />;
    } else if (
      selectedTab === "Supervisor" &&
      pathname.includes("reviewpeformance")
    ) {
      return <ReviewPeformance/>;
    } else if (selectedTab === "HR" && pathname.includes("adminapproval")) {
      return <AdminApproval />;
    } else if (
      selectedTab === "Supervisor" &&
      pathname.includes("supervisorapproval")
    ) {
      return <SupervisorApproval />;
    } else if (
      selectedTab === "Supervisor" &&
      pathname.includes("supervisorreward")
    ) {
      return <SupervisorReward />;
    } else if (selectedTab === "HR" && pathname.includes("adminreview")) {
      return <AdminReview />;
    } else if (selectedTab === "HR" && pathname.includes("rewardperformance")) {
      return <RewardPerformance />;
    }
  };

  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <ul style={{ marginBottom: "1.5rem" }} className="nav nav-pills">
          
            <li className="nav-item">
              <span
                className={`nav-link ${
                  selectedTab === "Supervisor" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Supervisor")}
              >
                Supervisor
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link ${selectedTab === "HR" ? "active" : ""}`}
                onClick={() => handleTabClick("HR")}
              >
                HR
              </span>
            </li>
          </ul>
            {isAdmin ? (
            <SubMenu menuOptions={subMenuOptionsByTab[selectedTab]} />
          ) : (
            <SubMenu menuOptions={subMenuOptionsByTab[selectedTab]} />
          )}
        </div>
        <div className="w-75 ml-4 mt-3 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};
export default Recognition;
