import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJob, getMyJobsAction ,editJobAction,deleteJob,getJobLevelsAction} from "../../../redux/actions/jobActions";
import { SectionLoader } from "../../../components/PageLoader";
import icons from "../../../assets/icons/icon-collection.svg";
import EmptyState from "../../../components/EmptyState";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import LinkButton from "../../../components/LinkButton";
import Card from "../../../components/Card";
import CustomButton from "../../../components/CustomButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Layout from "../../../layout";
import ViewDetails from "../Home/Modal/ViewDetails";
import { getCountryAction } from "../../../redux/actions/countryActions";
import { getIndustryAction } from "../../../redux/actions/industryActions";
import { PropTypes, func, object, string } from "prop-types";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import CustomInput from "../../../components/CustomInput";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextArea from "../../../components/CustomTextArea";

import { FormControl, Select } from "@material-ui/core";
import SearchLocationInput from "../../../components/LocationComponent";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel
} from "@material-ui/core";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import cogoToast from "cogo-toast";
//import createDOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'


/*TODO:: Refactor this Page later -- tight deadlines*/

const MyJobs = () => {
  //const window = (new JSDOM('')).window
  //const DOMPurify = createDOMPurify(window)

  const [page] = useState(1);
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [section, setSection] = useState("details");
  const [subSection, setSubSection] = useState("details");
  const [loading, setLoading] = useState(false);
  const [, setLoadingMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInvite, setInviteOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState({});
  const textAreaRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [levelOptions, setLevelOptions] = useState({});
  const [industryOptions, setIndustryOptions] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillOptions, setSkillOptions] = useState({});
  const [editJobView, setEditJobView] = useState(false);
  const [duplicatedData, setDuplicatedData] = useState(null);

   
 
  const [campany_name, setcampany_name] = useState();
  const [location, setlocation] = useState();
  const [countryvalue, setcountryvalue] = useState();
  const [no_of_staff, setno_of_staff] = useState();
  const [role_title, setrole_title] = useState();
  const [job_levelvalue, setjob_level] = useState();
  const [reports_to, setreports_to] = useState();
  const [job_type, setjob_type] = useState();
  const [industryvalue, setindustryvalue] = useState();
  const [about_company, setabout_company] = useState();
  const [salary, setsalary] = useState();
  const [duration, setduration] = useState();
  const [isCompanyRepresentative, setisCompanyRepresentative] = useState();
  const [canRecruiterRequest, setcanRecruiterRequest] = useState();
  const [company_id, setcompany_id] = useState();
  const [newPost, setNewPost] = useState(false);
  
  
  const [jobIdValue, setJobIdValue] = useState("details");
  const [selectedCountry,setSelectedCountry] = useState();
  const { country,profile, industry, job, skill} = useSelector(
    (store) => store
  );
  const { countries } = country;
  const { industries } = industry;

  const { jobLevels } = job;
   const { allSkills } = skill;

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
   
    setSkillOptions(allSkills);
  }, [allSkills]);
 

  useEffect(() => {
     Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch)

    ]);
     
   
  }, [dispatch]);
  

  useEffect(() => {
    const options = {};
    industries &&  industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
    
  }, [industries]);

   
  useEffect(() => {
    //dispatch({ type: CLEAR_CREATE_USER });
    const promises = [
      getCountryAction(dispatch),
    ];
  }, [dispatch]);

  useEffect(() => {
    if (copied) {
      cogoToast.success("Link copied");
      setCopied(false);
    }
  }, [copied]);

  const { myJobs } = useSelector(({ job }) => job);
  const jobs = myJobs;
  

 



  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getMyJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSection("details");
    setAnchorEl(null);
    setOpen(false);
  };

  const handleEditJob = (job) =>{
     history.push(`/editjob/${job}`);
  }

  const viewJobDetails = () => {
    // history.push(`/home/${jobId}`);
    setSection("details");
    setSubSection("details");
  };

  const viewDocument = () => {
    // history.push(`/home/${jobId}`);
    setSection("details");
    setSubSection("documents");
  };

  const handleInviteClose = () => {
    setSection("details");
    setAnchorEl(null);
    setInviteOpen(false);
  };

  const openDetails = (job) => {
    // console.log("opening...", job);
    setCurrentJob(job);
    setSection("details");
    setOpen(true);
  };

  const openInviteDetails = (job) => {
    setCurrentJob(job);
    setSection("details");
    setInviteOpen(true);
  };

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopied(true);
  };

  // const handleChange = ({ target: { id, value, checked } }) => {
  //   setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  // };
 
  const handleChange = (e) => {
  if (e && e.target && e.target.id) {
    const { id, value } = e.target;
    console.log('Handling change for ID:', id, 'with value:', value);
    setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  } else {
    console.error('Invalid event structure:', e);
  }
};

 
  const [stateRecognition, setStateRecognition] = useState({
    campany_name:"",
    location:"",
    country:"",
    no_of_staff:"",
    role_title:"",
    job_level:"",
    reports_to:"",
    job_type:"",
    industry:"",
    about_company:"",
    salary:"",
    duration:"",
    isCompanyRepresentative:"",
    canRecruiterRequest:"",
    company_id: 0,
    skills_required: [],

   });



  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

   const handleUpdateSubmit = async (e) => {
        
    setLoading(true)
    e.preventDefault();
     
   await editJobAction(dispatch, {
      ...stateRecognition,
    });
       setLoading(false)
       setTimeout(()=>{
        window.location.reload(false);
       },3000);
  };


  const handleDelete = async (jobid) => {
        
    setLoading(true)
 
     
   await deleteJob(dispatch, jobid);
       setLoading(false)
       setTimeout(()=>{
       window.location.reload(false);
       },3000);
  };

 
  const handleChangeCountry = ({ target: { value } }) => {
    const countryID = value;
   setSelectedCountry(countryID);
   setStateRecognition({ ...stateRecognition, country: countryID });
    };


  const addSkill = ({ target: { value } }) => {
    const skills = value;
        setSelectedSkills(skills);
        handleChange({ target: { value: skills, name: "skills_required" } });
      };
  
      const displaySkills = () => {
        return selectedSkills.map((skill, index) => (
          <span
            className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 outlined"
            key={index.toString()}
          >
            {skill}
            <IconButton onClick={() => removeSkill(skill)}>
              <svg width="10" height="8">
                <use href={`${icons}#close-blue`}></use>
              </svg>
            </IconButton>
          </span>
        ));
      };
  

      const removeSkill = (skillToRemove) => {
        const skillArray = [...selectedSkills];
        const newSkillArray = skillArray.filter((skill) => skill !== skillToRemove);
        setSelectedSkills(newSkillArray);
       // handleChange({ target: { value: newSkillArray, name: "skills_required" } });
      };


  const title = `Job Referral for ${currentJob["role_title"]} at ${currentJob.company_name}`;
  const hashtags = ["workbrook", "job", "workbrookjobs"];

  return (
    <Layout isOnDuplicatePost={newPost} duplicatedDataValue={duplicatedData}>
       {/** Edit Job component */}
       {
        editJobView ? 
        <div style={{
          backgroundColor:'#fff',
          padding:20
      }}>
      <form onSubmit={handleUpdateSubmit}>

      <h2>Edit Job</h2>

          <CustomInput
            type="text"
            onChange={handleChange}
            name="campany_name"
            value={stateRecognition.campany_name}
            placeholder=""
            label="Name Of Company"
            id="campany_name"
              />
              <CustomInput
          type="text"
          onChange={handleChange}
          name="location"
          value={stateRecognition.location}
          label="location"
          id="location"
        />

      
          <InputLabel name="country-label">Select Country</InputLabel> 
          <Select
            labelId="country"
            name="country"
            label="country"
            value={stateRecognition.country}
            onChange={handleChangeCountry}
            className="fullwidth-select"
          >
            <MenuItem value="">Select a Country</MenuItem>
            {countries.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>

          <CustomSelect
            handleChange={handleChange}
            options={{
              0: "Select Staff Strength",
              "Sole Employee": "Sole Employee",
              "2-20": "2-20",
              "21-50": "21-50",
              "51-200": "51-200",
              "201-500": "201-500",
              "501-1000": "501-1000",
              "1,001-10,000": "1,001-10,000",
              "10,001-50,000": "10,001-50,000",
              "50,001+": "50,001+",
            }}
  
            name="no_of_staff"
            label="Staff Strength"
            id={"no_of_staff"}
            currentValue={stateRecognition.no_of_staff}
          />
          <CustomInput
            type="text"
            onChange={handleChange}
            name="role_title"
            value={stateRecognition.role_title}
            label="Role title"
            id="role_title"
          />
          
          <CustomInput
          type="text"
          onChange={handleChange}
          name="reports_to"
          value={stateRecognition.reports_to}
          label="Reports To"
          id="reports_to"
        />
        
        <CustomSelect
          handleChange={handleChange}
          options={{ 0: "Select Level", ...levelOptions }}
          id="job_level"
          name="job_level"
          label="Job Level"
          currentValue={levelOptions}
        />

        
        <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Type",
          "part-time": "Part-time",
          "full-time": "Full-time",
          contract: "Contract",
          internship: "Internship",
          volunteer: "Volunteer",
        }}
        id="job_type"
        name="job_type"
        label="Type of Employment"
        currentValue={stateRecognition.job_type}
      />
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select an Industry", ...industryOptions }}
        id="industry"
        name="industry"
        label="Industry"
        currentValue={stateRecognition.industry}
      />
   {/*   <CustomTextArea
        onChange={handleChange}
        name="about_company"
        value={stateRecognition.about_company}
        label="About Company/Role"
        id="about_company"
      /> */}
           <CKEditor
                    editor={ ClassicEditor }
                    data={stateRecognition.about_company}
                    config={ {
                      //toolbar: [ 'bold', 'italic' ]
                  } }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        setStateRecognition({ ...stateRecognition, ...{ about_company: data } });
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> 

    <SalaryRow
        handleChange={handleChange}
        salary={stateRecognition.salary}
        duration={stateRecognition.duration}
              />
              {/* <CustomSelect
        name="skills_required"
        handleChange={handleChange}
        options={skillOptions}
        id="skills"
        label="Skills"
        currentValue={stateRecognition.skills_required}
        multiple
      /> */}
              
    <FormControl variant="standard" className="custom-input w-25 w-100 mt-3">
        <label className="d-block mb-0">Skills</label>
        <Select
          id="skills"
          label="skills"
          onChange={addSkill}
          value={selectedSkills}
                  multiple={true}
                   className="fullwidth-select"
        >
          <MenuItem value=" ">
            <em>Add Skill (ex: Interior Design)</em>
          </MenuItem>
          {skillOptions &&
            skillOptions.filter &&
            skillOptions
              .filter(
                ({ title }) => !selectedSkills.find((val) => val === title)
              )
              .map(({ title, id }) => (
                <MenuItem key={id} value={title}>
                  {title}
                </MenuItem>
              ))}
        </Select>
        <div className="skills-count mt-3 w-100 d-flex align-items-center">
          <span className="blob rounded px-2 py-1 bg-green co-white">
            <b>{selectedSkills.length}</b>
          </span>
          <span className="ml-2 co-green medium-text">Skills count</span>
        </div>
        {selectedSkills[0] && (
          <div className="mt-5 w-100 d-flex flex-wrap">{displaySkills()}</div>
        )}
      </FormControl>

      <RadioGroup
        className="mt-2"
        name="isCompanyRepresentative"
        value={stateRecognition.isCompanyRepresentative}
        onChange={handleChange}
      >
        <label>Are you a company representative?</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>

      <RadioGroup
        className="mt-2"
        name="canRecruiterRequest"
        value={stateRecognition.canRecruiterRequest}
        onChange={handleChange}
      >
        <label>Allow recruiter reach out to you</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>
   
    

          <CustomButton
              type="submit"
              backgroundColor="#41B883"
              handleClick={handleUpdateSubmit}
              color="#fff"
              loading={false}
              text={"Save Update"}
              additionalClass="mx-3 mt-2"
            />
 

      </form>
      </div>
      :
      <></>

      }
      {/** Edit Job component */}

      <div className="row position-relative mt-3 align-items-stretch ">

      

        {loading  && jobs && !jobs[0] && <SectionLoader />}
        {!loading && jobs  && !jobs[0] && (
          <EmptyState text="There are no jobs posted by you" />
        )}
        
     
        {jobs && !editJobView && jobs[0] && (
          <>
            {jobs.map((job, index) => (
              <div
                key={index}
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-6"
              >
                
                <Menu
                    id={"profile-menu"+job.id}
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "15rem",
                        marginTop: "0.2rem",
                        borderRadius: "1rem",
                        boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
                      },
                    }}
                  >


 

                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={() => {
                       // handleEditJob(jobIdValue)
                     
                        setStateRecognition({
                          campany_name:campany_name,
                          location:location,
                          country:selectedCountry,
                          no_of_staff: no_of_staff,
                          role_title: role_title,
                          job_level: job_levelvalue,
                          reports_to: reports_to,
                          job_type:job_type,
                          industry:industryvalue,
                          about_company:about_company,
                          salary:salary,
                          duration:duration,
                          isCompanyRepresentative: isCompanyRepresentative,
                          canRecruiterRequest: canRecruiterRequest,
                          company_id:jobIdValue
                         // skills_required: JSON.stringify(selectedSkills)
                         
                        });
                        setEditJobView(true);
                       // console.log(JSON.stringify(job.id))
                      }}>
                      Edit this job 
                    </MenuItem>
                    
                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={() => {
                        
                        setNewPost(true)
                        handleClose()
                        setDuplicatedData({
                          campany_name:campany_name,
                          location:location,
                          country:selectedCountry,
                          no_of_staff: no_of_staff,
                          role_title: role_title,
                          job_level: job_levelvalue,
                          reports_to: reports_to,
                          job_type:job_type,
                          industry:industryvalue,
                          about_company:about_company,
                          salary:salary,
                          duration:duration,
                          isCompanyRepresentative: isCompanyRepresentative,
                          canRecruiterRequest: canRecruiterRequest,
                          company_id:jobIdValue
                        })
                      }}>
                      Duplicate this job 
                    </MenuItem>

                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={()=>{
                        handleDelete(jobIdValue)
                      }
                    }
                    >
                      Delete this job
                    </MenuItem>
                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={handleClose}
                    >
                     Cancel
                    </MenuItem>
                    <Divider />
                    {/*<MenuItem
                      className="px-4 py-3 co-lightred medium-text"
                      onClick={handleClose}
                    >
                      Report This Post
                    </MenuItem> */}
                  </Menu>
             

                <>
                  <Card
                    additionalClass="mx-md-2 px-4 mb-4"
                    additionalStyle={{
                      height: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex mb-2 align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          width="30"
                          className="rounded-circle"
                          src={
                            job["created_by_profile"] &&
                            job.created_by_profile.image_url
                              ? job.created_by_profile.image_url
                              : ""
                          }
                          alt="avatar"
                        />
                        <span className="d-flex flex-column ml-3">
                          <h6 className="mb-0">
                            {job.created_by_profile &&
                              job.created_by_profile.first_name}{" "}
                            {job.created_by_profile &&
                              job.created_by_profile.last_name}
                          </h6>

                  


                          <span className="co-gray">
                            @
                            {job.created_by_profile &&
                              job.created_by_profile.username}
                          </span>
                        </span>
                      </div>
 
                     
                     
                      <IconButton onClick={(e) => {handleClick(e)
                      setJobIdValue(job.id)
                      setlocation(job.location);
                      setcountryvalue(job.country);
                      setno_of_staff(job.no_of_staff);
                      setrole_title(job.role_title);
                      setjob_level(job.job_level);
                      setjob_type(job.job_type);
                      setreports_to(job.reports_to);
                      setindustryvalue(job.industry);
                      setabout_company(job.about_company);
                      setcampany_name(job.campany_name)
                      setsalary(job.salary);
                      setduration(job.duration);
                      setisCompanyRepresentative(job.isCompanyRepresentative);
                      setcanRecruiterRequest(job.canRecruiterRequest);
                      
                      }}>
                        <svg width="20" height="15">
                          <use href={`${icons}#dots`}></use>
                        </svg>
                      </IconButton>
                    </div>

                    <Divider />

                    <div className="pb-3">
                      <b className="d-block my-3 co-primary">
                        {job.role_title}
                      </b>
                      <span>
                   
                        
                      
                      {/* <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.about_company.substring(0, 80)) }}></span> */}
                      { <span dangerouslySetInnerHTML={{ __html: job.about_company.substring(0, 80) }}></span> 
                      }

                        {"..."}                 
                        <button style={{ display: "inline" }}
                          type="button"
                          onClick={() => openDetails(job)}
                          className="co-primary bg-white borderless"
                        
                        >
                          <b>See More</b>
                        </button>
                        
                      </span>
                    </div>
                    
                 

                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="small-text thin-text">
                        {job.total_likes} Likes
                      </span>
                      {/* <span>
                        <img
                          src={SmallPlaceholder}
                          alt="avatar"
                          className="mx-1"
                        />
                        <img
                          src={SmallPlaceholder}
                          alt="avatar"
                          className="mx-1"
                        />
                        <img
                          src={SmallPlaceholder}
                          alt="avatar"
                          className="mx-1"
                        />
                      </span> */}
                    </div>

                    <Divider />

                    <div className="d-flex pt-2 align-items-center justify-content-end">
                      <LinkButton
                        label="Invite to Refer"
                        onClick={() => openInviteDetails(job)}
                      />
                    </div>

                  </Card>

                  
             
                  {open && (
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      PaperProps={{
                        style: {
                          minWidth: "50rem",
                          borderRadius: "1rem",
                          padding: "0.75rem",
                        },
                      }}
                    >
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <DialogTitle id="details-dialog-title">
                            <div>
                              <h2 className="mb-0">{currentJob.role_title}</h2>
                              <p className="mb-0 co-darkgray">
                                {currentJob.campany_name}
                              </p>
                            </div>
                          </DialogTitle>
                          <div className="close-button">
                            <IconButton onClick={handleClose}>
                              <svg
                                width="30"
                                height="30"
                                className="rounded-circle"
                              >
                                <use href={`${icons}#close`}></use>
                              </svg>
                            </IconButton>
                          </div>
                        </div>
                        <div className="position-relative sticky">
                          <div className="bg-white w-100 position-absolute">
                            <div className="d-flex px-4 justify-content-start">
                              <button
                                onClick={viewJobDetails}
                                className="co-white mr-2 px-4 py-2 fw-bold border-0 bg-primary rounded"
                              >
                                Job
                              </button>
                              <button
                                onClick={viewDocument}
                                className="co-white px-4 py-2 fw-bold border-0 bg-darkgrey rounded"
                              >
                                Additional Documents
                              </button>
                            </div>
                            <div className="d-flex justify-content-start ml-4 my-3">
                              <span className="mr-2">
                                <svg width="17" height="16" className="mr-1">
                                  <use href={`${icons}#briefcase`}></use>
                                </svg>
                                {currentJob?.job_level?.title}
                              </span>
                              <span className="mr-2">
                                <svg width="14" height="14" className="mr-1">
                                  <use href={`${icons}#mappin`}></use>
                                </svg>
                                {currentJob.location}
                              </span>
                              <span className="mr-2">
                                <svg width="24" height="17" className="mr-1">
                                  <use href={`${icons}#persons`}></use>
                                </svg>
                                {currentJob.no_of_staff} workers
                              </span>
                            </div>
                            <Divider />
                          </div>
                        </div>

                        <DialogContent>
                          <div className="mt-5 pt-5">
                            {section === "details" && (
                              <ViewDetails
                                job={currentJob}
                                section={subSection}
                              />
                            )}
                          </div>
                        </DialogContent>
                        {/* {!hideActions && (
                        <DialogActions>
                          
                          
                        </DialogActions>
                      )} */}
                      </>
                    </Dialog>
                  )}
                  {openInvite && (
                    <Dialog
                      open={openInvite}
                      onClose={handleInviteClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <div className="py-4 mt-4">
                          <h5 className="co-primary text-center">
                            Refer people using your unique link
                          </h5>
                          <p className="mx-auto w-75 my-5 w-100">
                            <textarea
                              ref={textAreaRef}
                              value={`${process.env.REACT_APP_WEB_URL}/home/${job.id}/apply/${job.created_by_profile.refCode}`}
                              className="borderless w-100 copy-link"
                              onChange={() => {}}
                            />
                          </p>
                          <div className="d-flex px-4 mt-3 justify-content-between">
                            <CustomButton
                              backgroundColor="#41B883"
                              color="#fff"
                              text="Copy Link"
                              handleClick={copyToClipboard}
                              additionalClass="mr-3 bg-primary"
                            />
                            <CustomButton
                              backgroundColor="#41B883"
                              color="#fff"
                              text="Share to Social"
                              handleClick={copyToClipboard}
                              additionalClass="bg-dargrey"
                            />
                          </div>
                          <div
                            className="mx-auto w-75 w-100 mt-4"
                            style={{ textAlign: "center" }}
                          >
                            <h6>Share to Social Network</h6>
                            <div>
                              <FacebookShareButton
                                url={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${job.id}/apply/${job.created_by_profile.refCode}`}
                                quote={title}
                                className="mr-2"
                                hashtag="#workbrook #job"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${job.id}/apply/${job.created_by_profile.refCode}`}
                                title={title}
                                className="mr-2"
                                hashtags={hashtags}
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>

                              <LinkedinShareButton
                                url={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${job.id}/apply/${job.created_by_profile.refCode}`}
                                className="mr-2"
                              >
                                <LinkedinIcon size={32} round />
                              </LinkedinShareButton>
                            </div>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  )}
                </>
              </div>
            ))}
            {/* {count > 10 * page && (
              <div className="mx-auto mt-3 w-75">
                <CustomButton
                  handleClick={() => setPage(page + 1)}
                  text={loadingMore ? <ButtonLoader /> : "Load More"}
                />
              </div>
            )} */}
          </>
        )}
      </div>
    </Layout>
  );
};

export default MyJobs;
const SalaryRow = ({ handleChange, duration }) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleBlur = (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "text";
    const stripped = text.replaceAll(",", "");
    const formatted = numberWithCommas(stripped);
    document.querySelector("#report-salary").value = formatted;
    handleChange({
      target: {
        value: text,
        name: "salary",
      },
    });
  };
  const handleFocus = async (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "number";
    const stripped = text.replaceAll(",", "");
    if (!isNaN(stripped))
      document.querySelector("#report-salary").value = stripped;

    handleChange({
      target: {
        value: stripped,
        name: "salary",
      },
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <div style={{ marginRight: "10px", flexGrow: 1 }}>
        <CustomInput
          type="number"
          name="salary"
          label="Salary (in USD)"
          id="report-salary"
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginRight: "10px",
          flexGrow: 1,
          marginTop: "13px",
        }}
      >
        <FormControl
          variant="standard"
          className="custom-input w-25 w-100 mt-4"
        >
          <Select
            id={duration}
            label="Duration"
            name="duration"
            onChange={handleChange}
            value={duration}
          >
            <MenuItem key="5" value="Hourly">
              Hourly
            </MenuItem>
            <MenuItem key="1" value="Daily">
              Daily
            </MenuItem>
            <MenuItem key="2" value="Weekly">
              Weekly
            </MenuItem>
            <MenuItem key="3" value="Monthly">
              Monthly
            </MenuItem>
            <MenuItem key="4" value="Annually">
              Annually
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};


SalaryRow.propTypes = {
  handleChange: func.isRequired,
  duration: string,
};
